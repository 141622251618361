let SEC = SEC || {};

SEC.checkout = (function () {
  let $checkoutForm;

  let currentStep;
  let activeShippingMethod;
  let cdekWidget;
  let shippingCity;

  let isGaCallbackCalled;

  const bindCitySelect = () => {
    const $citySelect = $checkoutForm.querySelector('.js-city-select');
    const $autoCity = $checkoutForm.querySelector('.js-shipping-city-auto');

    if (!$citySelect || !$autoCity) {
      return false;
    }

    if ($autoCity) {
      SEC.clickyLog('#checkout__autoCity', $autoCity.innerText);
    }

    $citySelect.addEventListener('change', () => {
      const selectedCity = $citySelect.options[$citySelect.selectedIndex].text;
      SEC.clickyLog('#checkout__citySelected', selectedCity);
      window.dataLayer.push({
        'event': 'citySelected',
        'selectedCity': selectedCity
      });
      aniFade($autoCity, selectedCity);
    });
  };

  const activateChoiceWidgetOnSubmit = () => {
    $checkoutForm.addEventListener('submit', (e) => {
        e.preventDefault();
        const $checkedShippingMethod = $checkoutForm.querySelector('input[name=shipping_method]:checked');
        if ($checkedShippingMethod) {
            const method = $checkedShippingMethod.value;
            if (method !== 'boxberry' && method !== 'cdek') {
                return false;
            }
            const $pointHidden = $checkoutForm.querySelector('input[name=' + method + '_num]');
            if (!$pointHidden || !$pointHidden.value) {
                addClass($checkoutForm, 'js-wait-choice');
                openChoiceWidget(null, method);
            }
        }
    });
  };

  const displayChosenPointData = (pointId, pointAddress, pointType) => {
    if (!pointId) {
      return false;
    }
    // let pointMsg = getPointTypesByMethod(activeShippingMethod, pointId);
    const pointMsg = pointType + ' ' + pointId + ': <b>' + pointAddress + '</b>';
    const $li = $checkoutForm.querySelector('li.active');
    let $pointData = $li.querySelector('.chosen-point-data');
    if (!$pointData) {
      $pointData = document.createElement('p');
      $pointData.className = 'chosen-point-data';
      const $widgetLink = $li.querySelector('.point-selector-link');
      const $prevP = $widgetLink.parentNode;
      $prevP.parentNode.insertBefore($pointData, $prevP);
    }
    $pointData.innerHTML = 'Выбран ' + pointMsg;
  };

  const updateChosenPointHidden = (pointId, method) => {
    if (!pointId || !method) {
      return false;
    }
    const $li = $checkoutForm.querySelector('li.active');
    if (!$li) {
      return false;
    }
    let $hidden = $li.querySelector('input[name=' + method + '_num]');
    if (!$hidden) {
      $hidden = document.createElement('input');
      $hidden.setAttribute('type', 'hidden');
      $hidden.setAttribute('name', method + '_num');
      $li.appendChild($hidden);
    }
    $hidden.value = pointId;
  };

  const updateChosenPointOnBackend = (pointData, method) => {
    const formData = new FormData();
    formData.set('point_num', pointData['id']);
    formData.set('method', method);
    axios
      .post('/checkout/update-chosen-point/', formData)
      .then((response) => {
        if (response.data['result'] === 'CITY_CHANGED') {
          window.location = '/checkout/payment-method/'
        } else if (response.data['result'] === 'NO-POSTAMAT') {
          console.log(pointData);
          let pointType = 'пункт выдачи';
          if (pointData['type'] && pointData['type'] === 'POSTAMAT') {
            pointType = 'постамат';
          }
          displayChosenPointData(pointData['id'], pointData['address'], pointType);
        } else {
          displayChosenPointData(pointData['id'], response.data['address'], response.data['point_type']);
        }
      });
  }

  const updateChosenPoint = (pointData) => {
    const $activeLi = $checkoutForm.querySelector('li.active');
    addClass($activeLi, 'point-chosen')
    const $activeMethod = $activeLi.querySelector('input[name=shipping_method]');
    if (!$activeMethod) {
      return false;
    }
    const method = $activeMethod.value;
    updateChosenPointOnBackend(pointData, method);
    updateChosenPointHidden(pointData['id'] || pointData['code'], method);
  };

  const pointChosenCallback = (pointData) => {
    // console.log(pointData);
    removeClass($checkoutForm, 'js-wait-choice');
    updateChosenPoint(pointData);
  };

  const boxberryPointChosenCallback = (pointData) => {
    pointChosenCallback(pointData);    
  };

  const cdekPointChosenCallback = (deliveryMode, tariffData, pointData) => {
    cdekWidget.close();
    pointData['id'] = pointData['code'];
    pointChosenCallback(pointData)
  };

  const openChoiceWidget = (e, method) => {
    if (e) {
      e.preventDefault();
    }
    if (!method) {
      let $el = e.target;
      if (!$el) {
        return false;
      } else if ($el.tagName == 'SPAN') {
        $el = $el.parentNode;
      }
      method = $el.dataset.method;
      if (!method) {
        return false;
      }
    }
    setShippingMethod(method);
    if (method == 'pickpoint') {
      PickPoint.open(pointChosenCallback, {ikn: '9990100712'});
    } else if (method == 'boxberry') {
      boxberry.open(boxberryPointChosenCallback, '1$14395df737f4ec800199cbe1ae0db13b', shippingCity, '', 1000, 1200, 0);
    } else if (method == 'cdek') {
      cdekWidget.open();
    }
  };

  const createWidgetLink = ($widget) => {
    const $widgetLink = document.createElement('a');
    $widgetLink.setAttribute('href', '#');
    $widgetLink.className = 'anchor perm point-selector-link';
    const $parent = $widget.parentNode;
    const $pointData = $parent.querySelector('.point-data');
    const methodsData = {
      'pickpoint': ['постамат/пункт выдачи', 'PickPoint'],
      'boxberry': ['пункт выдачи', 'Boxberry'],
      'cdek': ['пункт выдачи', 'СДЭК']
    }
    const method = $widget.dataset.method;
    if (!method) {
      return false;
    };
    if (method === 'cdek') {
      if (typeof window.CDEKWidget !== 'undefined') {
        cdekWidget = new window.CDEKWidget({
          // servicePath: 'https://paradis-amour.ru/cdek-service/',
          servicePath: '/cdek-service/',
          apiKey: '236ce93f-5e36-4f1a-8661-7b8d5dd62bb6',
          defaultLocation: shippingCity,
          popup: true,
          hideDeliveryOptions: {
            door: true
          },
          hideFilters: {
            have_cashless: true,
            have_cash: true,
            is_dressing_room: true,
            type: true
          },
          onChoose : cdekPointChosenCallback
        });    
      } else {
        return;
      }
    }
    const mData = methodsData[method]
    if ($pointData) {
      $widgetLink.innerHTML = 'Выбрать другой ' + mData[0];
    } else {
      $widgetLink.innerHTML = 'Выбрать <span class="chosen-visible">другой </span>' + mData[0] + ' ' + mData[1] + '<span class="chosen-invisible"> на карте</span>';
    }
    const $p = document.createElement('p');
    $p.appendChild($widgetLink);
    $widget.parentNode.replaceChild($p, $widget);
    $widgetLink.dataset.method = method;
    $widgetLink.addEventListener('click', openChoiceWidget);
  };

  const createWidgetLinks = () => {
    const $$widgets = $checkoutForm.querySelectorAll('.js-choice-widget');
    for (var i=0; i<$$widgets.length; i++) {
      createWidgetLink($$widgets[i]);
    }
  };

  const setShippingMethod = (shippingMethod) => {
    const $$radios = $checkoutForm.querySelectorAll('input[name=shipping_method]');
    deactivateAllRadios($$radios);
    for (var i=0; i<$$radios.length; i++) {
      if ($$radios[i].value == shippingMethod) {
        activateRadio($$radios[i]);
        break;
      }
    }
    // const $shippingMethod = document.querySelector('input[name=shipping_method][value=' + shippingMethod + ']');
    // $shippingMethod.checked = true;
  };

  const prepareGaForCheckoutStep = (step, option, callback) => {
    const stepData = {
      'step': step ? step : currentStep
    };
    if (option) {
      stepData['option'] = option;
    }

    const gaData = {
      'ecommerce': {
        'checkout': {
          'actionField': stepData,
        }
      },
      'event': 'gtm-ee-event',
      'gtm-ee-event-category': 'Enhanced Ecommerce',
      'gtm-ee-event-action': 'Checkout Step ' + stepData['step'],
      'gtm-ee-event-non-interaction': 'False',
    };

    if (callback) {
      gaData['eventCallback'] = callback;
    }

    return gaData;
  };

  const handleGaFormSubmit = () => {
    $checkoutForm.addEventListener('submit', (e) => {
      e.preventDefault();

      if (hasClass($checkoutForm, 'js-wait-choice')) {
        return false;
      }

      let optionValue;
      if (currentStep === 3) {
        // shipping-city
        const $citySelect = document.getElementById('id_city');
        if ($citySelect) {
          const selectedCity = $citySelect.options[$citySelect.selectedIndex];
          if (selectedCity.value === '0') {
            // город не выбран, смотрим в shipping_city_auto
            const $autoCity = $checkoutForm.querySelector('.js-shipping-city-auto');
            if ($autoCity) {
              optionValue = $autoCity.innerText;
            }
          } else {
            optionValue = selectedCity.text;
          }
        }
      }

      if (currentStep === 4) {
        const $shippingMethod = $checkoutForm.querySelector('input[name=shipping_method]:checked');
        if ($shippingMethod) {
          optionValue = $shippingMethod.value;
        }
      }

      if (currentStep === 5) {
        const $paymentMethod = $checkoutForm.querySelector('input[name=payment_method]:checked');
        if ($paymentMethod) {
          optionValue = $paymentMethod.value;
        }
      }

      let callback;
      if (currentStep !== 2 && currentStep !== 7) {
        callback = () => {
          isGaCallbackCalled = true;
          $checkoutForm.submit();
        }
      }

      window.dataLayer.push(prepareGaForCheckoutStep(currentStep, optionValue, callback));

      setTimeout(() => {
        if (!isGaCallbackCalled) {
          $checkoutForm.submit();
        }
      }, 600);
    });
  };

  const recordGaCheckoutStart = () => {
    const step = 2;
    window.dataLayer.push(prepareGaForCheckoutStep(step));
  };

  const handleFormSubmitClick = () => {
    const $submit = $checkoutForm.querySelector('input[type=submit]');
    if (!$submit) {
      return false;
    }

    $checkoutForm.addEventListener('submit', (e) => {
      e.preventDefault();
      SEC.freezeButton($submit, 5000);
      if (hasClass($checkoutForm, 'js-wait-choice')) {
        return false;
      }
      $checkoutForm.submit();
    })
  };

  const showMethodChooser = ($chooser) => {
    if (!$chooser || !$chooser.dataset.options || $chooser.dataset.isMounted) {
      return;
    };
    const _$li = $chooser.closest('li.active');
    let $shippingCost;
    if (_$li) {
      $shippingCost = _$li.querySelector('.shipping-cost');
      if ($shippingCost) {
        $shippingCost.dataset.baseCost = $shippingCost.innerText.match(/\d+/)[0];
      };
    };
    const getFormattedCost = (cost) => {
      return cost + ' ₽';
    };
    const activateMethod = ($el) => {
      const activeClass = 'checkout-form__method-option--active';
      if (hasClass($el, activeClass)) {
        return;
      };
      const option = $el.dataset.option
      removeClass($chooser.querySelector('.' + activeClass), activeClass);
      addClass($el, activeClass);
      const $note = $el.closest('.radio_note');
      $note.dataset.method = option;
      // убираем хидден cdek_num для доставки курьером (если он есть)
      let hName = ['cdek_num', 'cdek_num_tmp'];
      if (option == 'self') {
        hName.reverse();
      };
      const $hidden = _$li.querySelector('input[name=' + hName[0] + ']');
      if ($hidden) {
        $hidden.setAttribute('name', hName[1]);
      }
      if ($shippingCost) {
        const baseCost = $shippingCost.dataset.baseCost;
        if (baseCost && $el.dataset.optionCost) {
          $shippingCost.innerText = getFormattedCost(parseInt(baseCost) + parseInt($el.dataset.optionCost));
        } else if ($shippingCost.dataset.baseCost) {
          $shippingCost.innerText = getFormattedCost($shippingCost.dataset.baseCost);
        }
      }
      const $title = _$li.querySelector('.shipping-title');
      if ($title) {
        if (option == 'self') {
          $title.innerText = 'СДЭК: в пункт выдачи/постамат  — ';
        } else if (option == 'courier') {
          $title.innerText = 'СДЭК: курьером по адресу  — ';
        }
      }
    };
    addClass($chooser, 'checkout-form__method-chooser');
    const options = $chooser.dataset.options.split('|');
    for (var i=0; i<options.length; i++) {
      const optionParts = options[i].split('~~');
      let optionText = optionParts[1];
      const $option = document.createElement('div');
      $option.dataset.option = optionParts[0];
      let optionClass = 'checkout-form__method-option';
      addClass($option, optionClass);
      if (optionParts.length === 3) {
        optionText += '<span class="option-cost">(+' + getFormattedCost(optionParts[2]) + ')</span>';
        $option.dataset.optionCost = optionParts[2];
      }
      $option.innerHTML = optionText;
      $chooser.appendChild($option);
      if (i === 0) {
        activateMethod($option);
      }
      $option.addEventListener('click', function(e) {
        activateMethod(this);
      });
    }
    $chooser.dataset.isMounted = '1';
  };

  const activateRadio = ($el) => {
    $el.checked = true;
    activeShippingMethod = $el.value;
    const $li = $el.closest('li');
    addClass($li, 'active');
    const $methodChooser = $li.querySelector('.js-method-chooser');
    if ($methodChooser) {
      showMethodChooser($methodChooser);
    };
  };

  const deactivateAllRadios = ($$radios) => {
    for (var i=0; i<$$radios.length; i++) {
      const $li = $$radios[i].closest('li');
      removeClass($li, 'active');
    }
  };

  const init = () => {
    if (SEC.page !== 'checkout') {
      return false;
    }

    $checkoutForm = document.querySelector('.checkout-form');
    if (!$checkoutForm) {
      return false;
    }

    if (hasClass($checkoutForm, 'js-shipping-city-form')) {
      currentStep = 3;
      bindCitySelect();
      recordGaCheckoutStart();
    }

    if (hasClass($checkoutForm, 'js-shipping-method-form')) {
      currentStep = 4;
      const $$radios = $checkoutForm.querySelectorAll('input[name=shipping_method]');
      for (var i=0; i<$$radios.length; i++) {
        $$radios[i].addEventListener('change', function(e) {
          deactivateAllRadios($$radios);
          activateRadio(this);
        });
        const $li = $$radios[i].closest('li');
        const $chosen = $li.querySelector('.chosen-point-data, .single-point-data');
        if ($chosen) {
          addClass($li, 'point-chosen');
        }
        if ($$radios[i].value == 'cdek') {
          const $note = $li.querySelector('.radio_note');
          if ($note) {
            $note.dataset.method='self';
          }
        }
      };
      const $shippingCity = document.querySelector('.js-shipping-city');
      if ($shippingCity) {
        shippingCity = $shippingCity.dataset.cityName;
      };
      createWidgetLinks();
      activateChoiceWidgetOnSubmit();
    }

    if (hasClass($checkoutForm, 'js-payment-method-form')) {
      currentStep = 5;
    }

    if (hasClass($checkoutForm, 'js-data-form')) {
      currentStep = 6;
    }

    handleFormSubmitClick();
    // handleGaFormSubmit();
  };

  return {
    init,
    prepareGaForCheckoutStep
  };

})();

document.addEventListener('DOMContentLoaded', function () {
  SEC.checkout.init();
});
